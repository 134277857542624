.mask-box {
  border-radius: 20px;
  position: relative;
  background: #c2d3fc;
  box-shadow: 1px 1px 2px rgb(165, 165, 165) inset;
}
.mask-box .mask {
  width: 90px;
  height: 30px;
  border-radius: 20px;
  background: #002D74;
  position: absolute;
  box-shadow: 1px 0 2px #1976D2;
  transition: all 0.5s ease;
}
.mask-box .MuiButton-root {
  border-radius: 20px;
  width: 90px;
  height: 30px;
  font-weight: bold;
  transition: all 0.2s 0.1s ease;
}
.mask-box .MuiButton-root:hover {
  opacity: 0.8;
}
.custom-input-border .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #353B43;
}